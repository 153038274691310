
// OVERLAY
.overlay    {
  position: fixed;
  background: black;
  opacity: 0.7;
  z-index: 10;
  bottom: -50%;
  right: -50%;
  border-radius: 50%;

  width: 100vw;
  height: 100vh;
  visibility: hidden;
  transition: all .5s ease-in-out;
}
.overlay.show    {
  visibility: visible;
  transform: scale(3);
}
.overlay.hide    {
  transform: scale(0);
}


// DRAWER
.drawer {
  max-width: 100%;
  right: -104%;
  position: fixed;
  top: 0;
  bottom: -1px;
  background: white;
  z-index: 30;
  transition: right .3s ease-in-out, left .3s ease-in-out, bottom .3s ease-in-out;
}
.swipeable    {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.drawer:after {
  content: "";
  position: absolute;
  bottom: -200px;
  width: 100%;
  height: 200px;
  background: white;
}
.drawer_scrollable    {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
.drawer.left {
  left: -104%;
}
.drawer.show {
  right: 0;
}
.drawer.left.show {
  left: 0;
}
.drawer.bottom {
  right: 0;
  left: 0;
  top: auto;
  bottom: -100%;
  padding: 20px;
}
.drawer.bottom.show {
  bottom: 0;
}
/*
 * MODAL
 */
.builder_modal_content    {
  width: 100%;
  z-index: 100;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 660px;
  max-height: 92%;
  overflow-y: auto;
}
.builder_modal_inner    {
  padding: 15px;
  background: white;
}
/*
 * CHECKBOX
 */
.chbx input {
  visibility: hidden;
}
.chbx input + span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #6c6c6c;
  vertical-align: middle;
  position: relative;
  font-family: "Font Awesome 5 Free";
}
.chbx input:checked + span:after {
  content: "✔";
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 17px;
  z-index: 3;
}

/*
 * LINKS
 */
.link_button a:link, .link_button a:visited,
.link_button button,
a.link_button:link, a.link_button:visited,
a.link_button:link, a.link_button:visited,
.link_button label, label.link_button {
  display: block;
  padding: 8px 10px;
  border-radius: 3px;
  margin-bottom: 0;

}
.link_button a:hover, a.link_button:hover,.link_button button:hover,
.link_button label:hover, label.link_button:hover {
  background: #e9e9e9;
}
.link_button a:active, a.link_button:active, .link_button button:active,
.link_button label:active, label.link_button:active,
.link_button a:active .font_primary, a.link_button:active .font_primary {
  color: white !important;
}

/*
      LOADER
 */
.loader {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*
 * BADGE
 */
.builder_badge    {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -15px;
  right: -15px;
  line-height: 18px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
}
.builder_badge.white    {
  background: white;
}

// SWITCH
$height: 20;
$width: 38;
.switch {
  position: relative;
  display: inline-block;
  width: #{$width}px;
  height: #{$height}px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 14px;
}
.slider:before {
  position: absolute;
  content: "";
  height: #{$height - 8}px;
  width: #{$height - 8}px;
  left: 4px;
  bottom: 4px;
  border-radius: 14px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: red;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(#{$width - $height}px);
}

/*
  INSTALL BLOCK
 */
.install_block   {
  position: fixed;
  top: 100px;
  right: 70px;
  background: white;
  border: 3px solid red;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
  font-size: 1.2rem;
  color: inherit;
}
@media only screen and (max-width: 760px) {
  .install_block {
    padding: 15px;
    margin-top: 50px;
    position: static;
    font-size: 1.1rem;
  }
}