$hover: #3293ff;
$selected: #ff3700;
$builderBcg: #ede4ff;

@for $i from 6 through 9 {
  .font_0#{$i}   { font-size:  #{$i/10}rem }
}
@for $i from 1 through 30 {
  .font_#{$i}   { font-size:  #{1+(($i - 1)/10)}rem }
}
@for $i from 1 through 40 {
  .mb_#{$i*5}   { margin-bottom:  #{$i*5}px }
  .mt_#{$i*5}   { margin-top:  #{$i*5}px }
  .ml_#{$i*5}   { margin-left:  #{$i*5}px }
  .mr_#{$i*5}   { margin-right:  #{$i*5}px }
  .pb_#{$i*5}   { padding-bottom:  #{$i*5}px }
  .pt_#{$i*5}   { padding-top:  #{$i*5}px }
  .pl_#{$i*5}   { padding-left:  #{$i*5}px }
  .pr_#{$i*5}   { padding-right:  #{$i*5}px }
}
a:link  { text-decoration: none !important; }
.prewrap   { white-space: pre-wrap; }
.pointer   { cursor: pointer }
.gray   { color: #9d9d9d
}
.btn, button, input, select, textarea, .btn:active, button:active, input:active, select:active, textarea:active   {
  box-shadow: none !important;
  outline: none !important;
}
/*
 * setting inside element (GREEN BUTTON) : MENU
 */
.builder_settings_hoverable {
  position: relative;
}
.builder_settings_btn {
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 3;
  display: none;
}
.builder_settings_hoverable:hover {
  box-shadow:0 0 0 1px $hover !important;
}
.builder_settings_hoverable:hover .builder_settings_btn  {
  display: block;
}

/*
 * LOOPER ELEMENT (elements add to top, add to bottom, move top...)
 */
.looper_inline    {
  display: inline-block;
}
.builder_element_hover:hover, .builder_element_empty:hover   {
  cursor: pointer;
  box-shadow:0 0 0 2px $hover !important;
}
.builder_element_hover_active    {
  position: relative;
  box-shadow:0 0 0 2px $selected !important;
}
.builder_element_settings_top, .builder_element_settings_bottom   {
  position: absolute;
  left: 20%;
  top: -24px;
  margin-left: -11px;
}
.builder_element_settings_bottom   {
  top: auto;
  bottom: -24px;
}
.builder_element_settings_top button, .builder_element_settings_bottom button {
  background-color: $selected;
  color: white;
  font-size: 12px;
  border-radius: 2px;
  padding: 3px 6px;
}
/*
 * GRID AND LOOPER EMPTY
 */
.builder_element_empty   {
  padding: 15px;
  color: #5c5c5c;
  background-color: $builderBcg;
}
/*
 *  GRIDS
 */
$grid: #6666fb;
.builder_grid_selected    {
  position: relative;
  z-index: 4;
}
.builder_grid_hover  {
  position: relative;
  padding: 10px;
  border: 1px dashed $grid;
  z-index: 3;
}
.builder_grid_this_selected  {
  border: 1px dashed $selected;
  box-shadow:0 0 0 2px $selected !important;
}
.builder_grid_bottom    {
  position: absolute;
  right: -1px;
  bottom: -24px;
}
.builder_grid_bottom button   {
    padding: 1px 6px
}
.builder_grid_bottom button.btn-info    {
  background-color: $grid;
}
/*
 * SVG
 */
svg.white    {
  fill: white;
  stroke: white;
}